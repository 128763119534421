import React from "react";
import { Layout } from "../components/Layout";
import { SEO } from "../components/SEO";
import { CookieGuard } from "../renderProps/CookieGuard";

const FourOhFourPage = () => {
    return (
        <CookieGuard
            render={() => {
                return (
                    <Layout>
                        <SEO title="404" meta={[]} lang={"en-au"} />
                        <h2>Page not Found</h2>
                        <p>That page does not exist.</p>
                    </Layout>
                );
            }}
        />
    );
};

export default FourOhFourPage;
